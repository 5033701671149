import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import redStar from "../images/red_star.svg"

import Seo from "../components/seo"
import Comment from "../components/comment"

import "../styles/index.scss"
import "../styles/morevid.scss"

const IndexPage = () => (
  <>
    <Seo title="Making YouTube" />
    <div className="body">
      <div className="topNav">
        <StaticImage
          src="img/ytlogo.png"
          className="ytlogo"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="image"
          style={{ height: `auto`, width: `125px`, marginTop: "5px" }}
        />
        <div className="topNavItems">
          <p><a href="/"><strong>Sign Up</strong></a> &nbsp;|&nbsp; <a href="/">My Account</a> &nbsp;|&nbsp; <a href="/">History</a> &nbsp;|&nbsp; <a href="/">Help</a> &nbsp;|&nbsp; <a href="/">Log In</a> &nbsp;|&nbsp; <a href="/">Site: </a></p>
          <StaticImage
            src="img/pix-earth-01.png"
            className="earth-icon"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="image"
            style={{ height: `12px`, width: `12px`, marginRight: "5px" }}
          />
        </div>
      </div>
      <div className="header">
        <div className="headerTabs">
          <div className="headerTab">Videos</div>
          <div className="headerTab">Categories</div>
          <div className="headerTab">Channels</div>
          <div className="headerTab">Community</div>
        </div>
        <div className="searchDiv">
          <input type="text" style={{width:"258px", height:"20px"}}></input><button style={{height:"20px", lineHeight:"12px",marginLeft:"10px", cursor:"pointer"}}>Search</button>
        </div>
      </div>
      <div className="main">
        <h2>Making YouTube</h2>
        <div className="columns">
          <div className="leftMain">
            <div className="player">
              {/* <div style={{backgroundColor:"black",width:"480px",height:"300px"}}></div> */}
              <iframe 
                width="480" 
                height="250" 
                src="https://www.youtube.com/embed/XAvpwntKrEU" // <- @PHIL, UPDATED VIDEO LINK WILL GO HERE INSTEAD OF vJLW_JmoL5o
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowFullScreen
              ></iframe>
            </div>
            <div className="playerDetails">
              <div className="pd_row1">
                <div className="pd_row1_rating">
                  <p><a href="/">Login</a> to rate</p>
                  <div className="rating_stars">
                    <img src={redStar} className="redStar" alt="rating star" />
                    <img src={redStar} className="redStar" alt="rating star" />
                    <img src={redStar} className="redStar" alt="rating star" />
                    <img src={redStar} className="redStar" alt="rating star" />
                    <img src={redStar} className="redStar" alt="rating star" />
                  </div>
                  <p>231 ratings</p>
                </div>
                <div className="pd_row1_actions">
                  <div className="col">
                    <div className="pd_row1_action">
                      <StaticImage
                        src="img/pix-heart.png"
                        className="heart-icon"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="image"
                        style={{ height: `12px`, width: `13px`, marginRight: "5px" }}
                      />
                      <a href="/">Save to Favorites</a>
                    </div>
                    <div className="pd_row1_action">
                      <StaticImage
                        src="img/pix-group.png"
                        className="group-icon"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="image"
                        style={{ height: `12px`, width: `19px`, marginRight: "5px" }}
                      />
                      <a href="/">Add to Groups</a>
                    </div>
                  </div>
                  <div className="col">
                    <div className="pd_row1_action">
                      <StaticImage
                        src="img/pix-mail-01.png"
                        className="mail-icon"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="image"
                        style={{ height: `12px`, width: `17px`, marginRight: "5px" }}
                      />
                      <a href="/">Share Video</a>
                    </div>
                    <div className="pd_row1_action">
                      <StaticImage
                        src="img/pix-papers-01.png"
                        className="papers-icon"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="image"
                        style={{ height: `14px`, width: `13px`, marginRight: "5px" }}
                      />
                      <a href="/">Post Video</a>
                    </div>
                  </div>
                  <div className="col">
                    <div className="pd_row1_action">
                      <StaticImage
                        src="img/pix-cross-01.png"
                        className="cross-icon"
                        quality={95}
                        formats={["auto", "webp", "avif"]}
                        alt="image"
                        style={{ height: `14px`, width: `16px`, marginRight: "5px" }}
                      />
                      <a href="/" style={{lineHeight:"16px"}}>Flag as <br />Inappropriate</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pd_row2">
                <p>Views: <strong>235, 537</strong></p>
                <p>|</p>
                <p>Comments: <strong>147</strong></p>
                <p>|</p>
                <p>Favorited: <strong>500</strong> times</p>
              </div>
              <div className="pd_row3">
                <p>Honors: <span>21</span></p>
                <p>Links: <span>5</span></p>
              </div>
            </div>
            <div className="comments">
              <h4>Comments & Responses</h4>
              <div className="postOne">
                <p><strong><a href="/">Post a video response</a></strong></p>
                <p><strong><a href="/">Post a text comment</a></strong></p>
              </div>
              <div className="commentPages">
                <p><strong>Most Recent</strong></p>
                <p><strong>...</strong></p>
                <p><strong><a href="/">1</a></strong></p>
                <p><strong><a href="/">2</a></strong></p>
                <p><strong><a href="/">3</a></strong></p>
                <p><strong><a href="/">4</a></strong></p>
                <p><strong><a href="/">5</a></strong></p>
                <p><strong><a href="/">6</a></strong></p>
                <p><strong><a href="/">7</a></strong></p>
                <p><strong><a href="/">8</a></strong></p>
                <p><strong>...</strong></p>
                <p><strong><a href="/">Oldest</a></strong></p>
              </div>
              <div className="commentsDiv">
                <Comment 
                  username="Elliotts" 
                  timeAgo="2 minutes ago" 
                  comment="I don't need sleep. I need Daxflame movie"
                />
                <Comment 
                  username="CZsWorld" 
                  timeAgo="8 minutes ago" 
                  comment="go dax !!!"
                />
                <Comment 
                  username="kate104" 
                  timeAgo="17 minutes ago" 
                  comment="come to Brasil ! we love you dax"
                />
                <Comment 
                  username="Spykles" 
                  timeAgo="33 minutes ago" 
                  comment="first"
                />
                <Comment 
                  username="zenxshade" 
                  timeAgo="51 minutes ago" 
                  comment="wtf"
                />
                <Comment 
                  username="166beads" 
                  timeAgo="4 hours ago" 
                  comment="because it was one of the most viewed of the day, and I have an opinion too"
                />
                <Comment 
                  username="Danerules47" 
                  timeAgo="1 day ago" 
                  comment="I'm Daxflame partnership/friend!"
                />
                <Comment 
                  username="got2luvkd" 
                  timeAgo="1 day ago" 
                  comment="FREE BLACKBERRY NO INTEREST NOW: tekgiving.xyz/redirect?=zxbc9a87nma9"
                />
                <Comment 
                  username="charlie_puth91" 
                  timeAgo="1 day ago" 
                  comment="sux lol. least talented person on the site"
                />
                <Comment 
                  username="DrSpamalot" 
                  timeAgo="1 day ago" 
                  comment="Youtubers with cameras! Maybe you should think about joining THE KETCHUP CRUSADE! Are you up for the challenge?"
                />
              </div>
            </div>
          </div>
          <div className="sidebar">
            <div className="beta">
              <a href="https://www.youtube.com/c/Daxflame" target="_blank" rel="noreferrer"><strong>Try out the NEW (beta) version of this page!</strong></a>
            </div>
            <div className="about">
              <div>
                <StaticImage
                    src="img/daxpfp.jpeg"
                    className="daxpfp"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="image"
                    style={{ height: `50px`, width: `50px`, marginRight: `5px` }}
                />
                <div className="stats">
                  <p><strong>From: <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></strong></p>
                  <p><strong>Joined:</strong> 11 months ago</p>
                  <p><strong>Videos</strong> 122</p>
                </div>
                <a href="https://www.youtube.com/c/Daxflame?sub_confirmation=1" target="_blank" rel="noreferrer"><button className="subscribe" aria-label="subscribe button"><strong>Subscribe</strong></button></a>
              </div>
              <div className="aboutSection">
                {/* <img src="https://web.archive.org/web/20071208103823im_/http://youtube.com/img/pixel.gif" className="arrow" alt="" /> */}
                <h4>About This Video</h4>
                <p>Sorry I was talking so fast. Hello, I am just m... (more)</p>
                <p><strong>Added</strong> December 31, 2006</p>
              </div>
              <div className="embedDiv">
                <p className="inline"><strong>Embed</strong></p>
                <p className="inline righty"><a href="/">customize</a></p>
                <input id="embed_code" name="embed_code" className="embedField" type="text" defaultValue='<iframe width="560" height="315" src="https://www.youtube.com/embed/vJLW_JmoL5o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>' readOnly="" style={{width: "340px", height: "20px", marginTop: "0px"}}></input>
              </div>
            </div>
            <StaticImage
              src="img/holiday.jpeg"
              className="holiday"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="image"
              style={{ height: `auto`, width: `100%`, margin: "10px 0" }}
            />
            <h4>More from: Daxflame</h4>
            <div className="moreVids">
              <div className="morevid">
                <StaticImage
                    src="img/vid_prank.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">I pulled the Ultimate Prank at Lunch!</a></strong></p>
                  <p>02:54 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 4,329,398</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_homedepot.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">Home Depot NIGHTMARE</a></strong></p>
                  <p>03:58 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 271,858</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_making.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">I am Making a Motion Picture</a></strong></p>
                  <p>00:48 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 164,309</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_suspended.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">Suspended for obeying the rules</a></strong></p>
                  <p>05:54 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 465,894</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_success.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">A success today when I asked Annie to date me today at school</a></strong></p>
                  <p>04:59 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 298,408</p>
                </div>
              </div>
            </div>

            <h4>Related Videos</h4>
            <div className="relatedVids">
              <div className="morevid">
                <StaticImage
                    src="img/vid_kidnap.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">Marriage of the KIDNAP!</a></strong></p>
                  <p>06:11 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 352,326</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_lisa.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame interviews LisaNova!</a></strong></p>
                  <p>09:46 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 464,344</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_gf.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">A NEW GIRLFRIEND</a></strong></p>
                  <p>01:00 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 289,827</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_haircut.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">Haircut/How hard is it to get an order right?</a></strong></p>
                  <p>06:28 <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> 195,463</p>
                </div>
              </div>
              <div className="morevid">
                <StaticImage
                    src="img/vid_xmas.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">Christmas stealing the fame! What holiday do you celebrate?</a></strong></p>
                  <p>02:16 <strong>From:</strong> <a href="/">davisville17</a></p>
                  <p><strong>Views:</strong> 1,519</p>
                </div>
              </div>
              {/* <div className="morevid">
                <StaticImage
                    src="img/vid_homedepot.jpeg"
                    className="thumbnail"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="thumbnail"
                    style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
                />
                <div className="morevidAbout">
                  <p><strong><a href="/">XX_TITLE_XX</a></strong></p>
                  <p>XX_DURATION_XX <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
                  <p><strong>Views:</strong> XX_VIEWS_XX</p>
                </div>
              </div> */}
            </div>
            {/* <div className="metadata">
              <p><strong>Added: July 12, 2006</strong></p>
              <p><strong>From: <a href="/">omeggl</a></strong></p>
              <p className="ankle"><strong>ankel breakers</strong></p>
              <p><strong>Category</strong> <span className="underline">Sports</span></p>
              <p><strong>Tags:</strong> <span className="underline">steve</span>  <span className="underline">francis</span>  <span className="underline">baron</span>  <span className="underline">davis</span>  (<a href="/">more</a>)</p>
              <p><strong>URL</strong></p>
              <p><strong>Embed</strong></p>
            </div> */}
          </div>
        </div>
        <div style={{marginTop:"40px"}}></div>
        <div className="topAd2">
          <StaticImage
              src="../images/obama_ad.png"
              className="adImg2"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="image"
              style={{ marginTop: `0.5rem`, marginBottom: `1.45rem` }}
          />
        </div>
      </div>
    </div>
  </>
)


export default IndexPage



//   <div className="morevid">
//   <StaticImage
//       src="img/vid_homedepot.jpeg"
//       className="thumbnail"
//       quality={95}
//       formats={["auto", "webp", "avif"]}
//       alt="thumbnail"
//       style={{ height: `60px`, width: `90px`, marginRight: `5px` }}
//   />
//   <div className="morevidAbout">
//     <p><strong><a href="/">XX_TITLE_XX</a></strong></p>
//     <p>XX_DURATION_XX <strong>From:</strong> <a href="https://web.archive.org/web/20071208103823/http://youtube.com/user/Daxflame" target="_blank" rel="noreferrer">Daxflame</a></p>
//     <p><strong>Views:</strong> XX_VIEWS_XX</p>
//   </div>
// </div>
