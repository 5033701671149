import * as React from "react"
import PropTypes from "prop-types"

import "../styles/comment.scss"

const Comment = ({ comment, timeAgo, username }) => (
    <div className="singleComment">
        <div className="userBox">
            <p><strong><a href="/asdf">{username}</a></strong> ({timeAgo})</p>
        </div>
        <p style={{textAlign:"right"}}>(<a href="/asdf">Reply</a>)</p>
        <p className="commentMessage">{comment}</p>
    </div>
)

Comment.propTypes = {
    comment: PropTypes.string,
    timeAgo: PropTypes.string,
    username: PropTypes.string,
}

Comment.defaultProps = {
    comment: "This is an example comment",
    timeAgo: "24 minutes ago",
    username: "user12345",
}

export default Comment
